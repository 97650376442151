import React from "react";
import GlobalHeaderContext, { headerDefault } from "../../../context/GlobalHeaderContext";

const PageWrapper = ({ children, headerConfig = null, innerPage = false, innerPageFooter = false }) => {
  const innerPageDefault = {
    headerClasses: "light-header site-header--menu-end site-header--button-sep position-relative",
    containerFluid: false,
    darkLogo: true,
  }
  const activeHeader = (innerPage ? innerPageDefault : headerDefault);
  const sitectx = React.useContext(GlobalHeaderContext);

  React.useEffect(() => {
    sitectx.changeHeader({ ...activeHeader, ...headerConfig });
  }, []);
  return (
    <>
      {/* <Nav /> */}
      {children}
    </>
  )
}

export default PageWrapper;