import React from "react";
import "./node_modules/slick-carousel/slick/slick.css";
import "./node_modules/react-modal-video/css/modal-video.min.css";
import "./src/assets/fonts/fontawesome-5/css/all.min.css";
import { Layout } from "./src/components/Core";
import { GlobalHeaderProvider } from "./src/context/GlobalHeaderContext";
import "./src/styles/scss/bootstrap.scss";
import "./src/styles/scss/global.scss";
//import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}


export const wrapRootElement = ({ element }) => (
  <GlobalHeaderProvider>{element}</GlobalHeaderProvider>
);

/*export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lcy2mAhAAAAACE5zfk1k2yFbIRkU5AzFtr2nBI4">
      {element}
    </GoogleReCaptchaProvider>
  )
}*/
